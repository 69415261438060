<template>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <button class="button" v-if="statusCheck" style="margin: 0 0 10px 0" @click="getPdfFile">
        Выгрузка Pdf
      </button>
      <!--      <button-->
      <!--        v-if="status === 'SENT'"-->
      <!--        class="button" disabled style="margin: 0 0 10px auto; background: lightgrey" @click="sendToMon"-->
      <!--      >-->
      <!--        Отправлено в МОН-->
      <!--      </button>-->
      <!--      <button-->
      <!--        v-if="status === 'BLOCKED'"-->
      <!--        class="button" disabled style="margin: 0 0 10px auto; background: lightgrey" @click="sendToMon"-->
      <!--      >-->
      <!--        Заблокировано-->
      <!--      </button>-->
      <!--      <button-->
      <!--        v-if="status === 'ACCEPTED_MON'"-->
      <!--        class="button" disabled style="margin: 0 0 10px auto; background: lightgrey" @click="sendToMon"-->
      <!--      >-->
      <!--        Согласовано МОН-->
      <!--      </button>-->
      <!--      <button-->
      <!--        v-if="status === 'ACCEPTED_GOV'"-->
      <!--        class="button" disabled style="margin: 0 0 10px auto; background: lightgrey" @click="sendToMon"-->
      <!--      >-->
      <!--        Согласовано Правительством-->
      <!--      </button>-->
    </div>
    <wrapper-preview-category customClass="section-preview_lk">
      <grid-table
        :data-rows="organizedDataForOrganization"
        :headers="tableHeaders.organizationMassive"
        :headers-for-rows="tableHeaders.organizationMassive"
        :loading-data="tableData.loading"
        num-cols="12"
        max-table-height="700px"
      >
        <template #totalRequestAmount="{ row }">
          <div>{{ totalRequestAmount(row.array) }}</div>
        </template>
        <template #sprSpecName="{ row }">
          <div>{{ `(${row.sprSpecCode}) ${row.sprSpecName}` }}</div>
        </template>
        <template #val="{ row }">
          <div v-for="(item, j) in row.array" :key="j">
            <div>{{ kcpVal(item) }}</div>
          </div>
        </template>
        <template #client="{ row }">
          <div v-for="(item, j) in row.array" :key="j">
            <div>{{ item.zakazchik }}</div>
          </div>
        </template>
        <!--        <template #organization="{ row }">-->
        <!--          <div v-for="(item, j) in row.array" :key="j">-->
        <!--            <div>{{ item.orgTrud }}</div>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--        <template #subject="{ row }">-->
        <!--          <div v-for="(item, j) in row.array" :key="j">-->
        <!--            <div>{{ regionsNamesList(item.sprRegions) }}</div>-->
        <!--          </div>-->
        <!--        </template>-->
        <template #sprOrgFounderName="{ row }">
          <div v-for="(item, j) in row.array" :key="j">
            <div>{{ item.sprOrgFounderName ? item.sprOrgFounderName : item.sprRegionName }}</div>
          </div>
        </template>
      </grid-table>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import QuotaTableHeaders from "@/components/GridTable/dataFolder/QuotaTableHeaders";
import GridTable from "@/components/GridTable/GridTable";
import axios from "axios";

export default {
  name: "OrganizationQuotaTable",
  components: {GridTable, WrapperPreviewCategory},
  data: () => ({
    tableHeaders: [],
    tableData: {
      loading: false,
      change: {value: false, id: null},
      error: null,
      editRow: null,
      data: [],
    },
    allDataForOrganization: [],
    organizedDataForOrganization: [],
    status: null,
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
    statusCheck() {
      if (this.allDataForOrganization[0]?.founderStatus === 'ACCEPTED_MON' ||
        this.allDataForOrganization[0]?.founderStatusRegion === 'ACCEPTED_MON') {
        return true
      } else {
        return false
      }
    }
  },
  
  created() {
    this.getAllByOrgId();
    this.tableHeaders = QuotaTableHeaders(this);
  },
  methods: {
    regionsNamesList(item) {
      let str = '';
      for (var r of item) {
        if (str !== '') {
          str += "; " + r.regionName;
        } else {
          str += r.regionName;
        }
      }
      return str;
    },
    
    async getAllByOrgId() {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/getAllByOrgId?orgId=${this.getUser.orgID}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          console.log('getAllByOrgId успех');
          this.allDataForOrganization = res.data.sort((a, b) => a.id - b.id);
          
          this.organizedDataForOrganization = [];
          
          if (this.allDataForOrganization[0]?.founderStatus === 'ACCEPTED_MON' || this.allDataForOrganization[0]?.founderStatusRegion === 'ACCEPTED_MON') {
            for (let i = 0; i < this.allDataForOrganization.length; i++) {
              if (this.organizedDataForOrganization.find(el => el.sprOrgName === this.allDataForOrganization[i].sprOrgName &&
                el.sprFoName === this.allDataForOrganization[i].sprFoName && el.sprSpecName === this.allDataForOrganization[i].sprSpecName
                && el.sprSpecCode === this.allDataForOrganization[i].sprSpecCode)) {
                const xxx = this.organizedDataForOrganization.indexOf(this.organizedDataForOrganization.find(el =>
                  el.sprOrgName === this.allDataForOrganization[i].sprOrgName &&
                  el.sprFoName === this.allDataForOrganization[i].sprFoName && el.sprSpecName === this.allDataForOrganization[i].sprSpecName
                  && el.sprSpecCode === this.allDataForOrganization[i].sprSpecCode))
                for (let j = 0; j < this.allDataForOrganization[i].array.length; j++) {
                  
                  if ((this.allDataForOrganization[i].founderStatus === 'ACCEPTED_MON' ||
                    this.allDataForOrganization[i].founderStatusRegion === 'ACCEPTED_MON' ||
                    this.allDataForOrganization[i].founderStatus === 'ACCEPTED_GOV' ||
                    this.allDataForOrganization[i].founderStatusRegion === 'ACCEPTED_GOV') && (this.allDataForOrganization[i].array[j].valStage3 > 0)) {
                    
                    /////////////////////// надстройка для отсеивания нулевых значений
                    this.allDataForOrganization[i].array[j].sprOrgFounderId = this.allDataForOrganization[i].sprOrgFounderId;
                    this.allDataForOrganization[i].array[j].sprOrgFounderName = this.allDataForOrganization[i].sprOrgFounderName;
                    this.allDataForOrganization[i].array[j].sprRegionId = this.allDataForOrganization[i].sprRegionId;
                    this.allDataForOrganization[i].array[j].sprRegionName = this.allDataForOrganization[i].sprRegionName;
                    this.allDataForOrganization[i].array[j].founderStatus = this.allDataForOrganization[i].founderStatus;
                    this.allDataForOrganization[i].array[j].founderStatusRegion = this.allDataForOrganization[i].founderStatusRegion;
                    this.organizedDataForOrganization[xxx].array.push(this.allDataForOrganization[i].array[j]);
                    ///////////////////////
                  }
                }
              } else {
                let sumArray= 0;
                for (let h = 0; h < this.allDataForOrganization[i].array.length; h++) {
                  if ((this.allDataForOrganization[i].founderStatus === 'ACCEPTED_MON' ||
                    this.allDataForOrganization[i].founderStatusRegion === 'ACCEPTED_MON' ||
                    this.allDataForOrganization[i].founderStatus === 'ACCEPTED_GOV' ||
                    this.allDataForOrganization[i].founderStatusRegion === 'ACCEPTED_GOV') && (this.allDataForOrganization[i].array[h].valStage3 > 0)) {
                    sumArray += this.allDataForOrganization[i].array[h].valStage3
                    ////////////////////////////////////////////////////// надстройка для отсеивания нулевых значений
                    this.allDataForOrganization[i].array[h].sprOrgFounderId = this.allDataForOrganization[i].sprOrgFounderId;
                    this.allDataForOrganization[i].array[h].sprOrgFounderName = this.allDataForOrganization[i].sprOrgFounderName;
                    this.allDataForOrganization[i].array[h].sprRegionId = this.allDataForOrganization[i].sprRegionId;
                    this.allDataForOrganization[i].array[h].sprRegionName = this.allDataForOrganization[i].sprRegionName;
                    this.allDataForOrganization[i].array[h].founderStatus = this.allDataForOrganization[i].founderStatus;
                    this.allDataForOrganization[i].array[h].founderStatusRegion = this.allDataForOrganization[i].founderStatusRegion;
                    /////////////////////////////////////////////////////
                  }
                }
                if (sumArray > 0)
                this.organizedDataForOrganization.push(this.allDataForOrganization[i]);
              }
            }
            this.sortMethod();
          }
          
          
          if (this.organizedDataForOrganization[0].founderStatus === 'ACCEPTED_GOV' || this.organizedDataForOrganization[0].founderStatusRegion === 'ACCEPTED_GOV') {
            this.status = 'ACCEPTED_GOV';
          } else if (this.organizedDataForOrganization[0].founderStatus === 'ACCEPTED_MON' || this.organizedDataForOrganization[0].founderStatusRegion === 'ACCEPTED_MON') {
            this.status = 'ACCEPTED_MON';
          } else if (this.organizedDataForOrganization[0].founderStatus === 'SENT' || this.organizedDataForOrganization[0].founderStatusRegion === 'SENT') {
            this.status = 'SENT';
          } else if (this.organizedDataForOrganization[0].founderStatus === 'BLOCKED' || this.organizedDataForOrganization[0].founderStatusRegion === 'BLOCKED') {
            this.status = 'BLOCKED';
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    sortMethod() {
      this.organizedDataForOrganization = this.organizedDataForOrganization.sort((a, b) => a.sprSpecCode.localeCompare(b.sprSpecCode))
      this.organizedDataForOrganization = this.organizedDataForOrganization.sort((a, b) => a.sprOrgName.localeCompare(b.sprOrgName))
    },
    
    totalRequestAmount(item) {
      let sum = 0;
      for (let i = 0; i < item.length; i++) {
        sum = sum + item[i].valStage3;
      }
      return sum
    },
    
    kcpVal(item) {
      return `${item.valStage3}`
    },
    
    async getPdfFile() {
      let uri = `/api/moduledatagather/report/org/pdf?orgId=${this.getUser.orgID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.saveByteArray("org.pdf", responseAxios.data)
    },
    
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/xlsx"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
  }
}
</script>

<style lang="sass">
.yellow-background
  background-color: #fff3cd
  color: #D4990A

.section-preview__header
  font-size: 24px
  font-weight: bold
  margin: 16px 0
  color: #334D6E

.dialog-btn
  .v-btn__content
    padding: 10px 5px

.v-autocomplete
  padding: 0 24px

.v-text-field
  padding: 0 24px
</style>
