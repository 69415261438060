<template>
  <div class="table-grid">
    <div
        :style="`grid-template-columns : repeat(${numCols}, 1fr); max-height: ${maxTableHeight};`"
        class="table-grid__container"
    >
      <!--       header -->
      <div
          ref="table-grid-header-row"
          :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
          class="table-grid-header-row"
      >
        <slot :headers="localHeaders" name="defaultHeader">
          <div
              v-for="(item, i) in localHeaders" :key="i"
              :ref="'table-grid-header__cell-'+item.dataField"
              :class="item.headerClass"
              :style="`grid-column: span ${(Number(item.cols) )}; grid-row: span ${Number(item.rows)};
               top: ${topFixedPositionForHeaderCell(item).offsetTop}px; ${item.headerStyles}`"
              class="table-grid-header__cell"
          >
            <slot :item="item" :name="'header-'+item.dataField">
              <span>{{ item.text }}</span>
              <div class="d-flex flex-row align-center justify-center">
                <v-tooltip
                    v-if="item.headerTooltip != null && item.headerTooltip"
                    color="#00599b"
                    top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        :size="15"
                        class="btn__icon grey-light-color__important"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <p class="white--text" v-html="item.headerTooltipText()"></p>
                </v-tooltip>
                <v-btn v-if="item.addButton" :disabled="$attrs.blocked" icon @click="$emit('add-dialog')">
                  <v-icon color="primary" size="40">mdi-plus</v-icon>
                </v-btn>
                <v-btn v-if="item.columnFilter" style="background: #00599b !important"
                       @click="$emit('filter-dialog', i)">
                  #
                </v-btn>
                <span
                    v-if="item.sortable"
                    class="d-flex align-center justify-center"
                >
<!--                <template v-if="sort.header === item.dataField">-->
                  <!--                  <v-btn icon @click="clickSort(item, sort.type ==='asc'? 'desc' : null, item.typeCell)">-->
                  <!--                     <v-icon v-if="sort.type === 'desc'" :size="24" class="btn__icon" color="primary">-->
                  <!--                       mdi-menu-down-->
                  <!--                     </v-icon>-->
                  <!--                     <v-icon v-else :size="24" class="btn__icon" color="primary"> mdi-menu-up</v-icon>-->
                  <!--                  </v-btn>-->
                  <!--                </template>-->
                  <!--                <template v-else>-->
                  <!--                    <v-btn-->
                  <!--                      icon-->
                  <!--                      @click="clickSort(item, null, item.typeCell)"-->
                  <!--                    >-->
                  <!--                       <v-icon :size="24" color="primary">mdi-menu-swap</v-icon>-->
                  <!--                    </v-btn>-->
                  <!--                </template>-->
          </span>
              </div>
            </slot>
          </div>
        </slot>
        <!--        <slot :headers="headers" name="customHeader"></slot>-->
      </div>
      <!--        body-->
      <template v-if="loadingData || loading">
        <div :style="`grid-column: span ${numCols}; min-height: 200px;`" class="d-flex flex-row align-center">
          <v-progress-linear indeterminate></v-progress-linear>
        </div>
      </template>
      <template v-else-if="dataRows.length === 0">
        <div :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr);
            `"
             class="table-grid-body-row"
        >
          <div
              :style="`grid-column: span ${(numCols )};  min-height: 200px!important;`"
              class="table-grid-body-row__cell"
          >
            Нет данных
          </div>
        </div>
      </template>
      <template v-else>
        <div
            v-for="(row, j) in dataRows"
            :key="(row.id != null ? row.id : j)"
            :ref="'table-grid-body-row__'+(row.id != null ? row.id : j)"
            :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
            class="table-grid-body-row"
            @click="rowAction($event,row)"
        >
          <div
              v-for="(cell) in headersForRows"
              :key="cell.dataField"
              :class="[cell.class, painCell(row, cell)]"
              :style="`grid-column: span ${(Number(cell.cols) )}; ` + `${cell.styles}`"
              class="table-grid-body-row__cell"
          >
            <slot :id="row.id != null ? row.id : j" :cell="cell" :name="cell.dataField" :row="row">
              <template>
                <span style="text-align: center" v-html="cellData(row, cell)"></span>
              </template>
            </slot>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/*
* [] - сортировка
* [] - выброс евента при нажатии на сортировку
* [] - не сортировать внутри, если есть пагиатор(типа), брать из пропсов
* [] -
* */
export default {
  name: "GridTable",
  props: {
    numCols: {
      type: [Number, String],
      default: 0
    },
    headers: {
      type: [Array],
      default: () => []
    },
    headersForRows: {
      type: [Array],
      default: () => []
    },
    maxTableHeight: {
      type: [String, Number],
      default: '550px',
    },
    loadingData: {
      type: Boolean,
      default: false
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paintBackground: {
      type: [Function],
      default: () => {
      }
    },
    importsComponents: {
      type: [Array, Object]
    }
  },
  watch: {
    headers(newValue) {
      this.loading = true;
      this.localHeaders = newValue;
      this.setTopPositions();
      this.loading = false;
    }
  },
  data() {
    return {
      localHeaders: this.headers,
      loading: false,
      sort: {header: null, type: 'asc'}
    }
  },
  mounted() {
    this.setTopPositions();
    // console.log(this.$refs["table-grid-header-row"]);
  },
  methods: {
    rowAction(e, row) {
      this.$emit('row-action', {e: e, row: row})
    },
    cellData(row, cell) {
      return cell.template ? cell.template(row) : row[cell.dataField]
    },
    clickSort(e, type = null, typeCell) {
      // console.log(e, ' - ', type);
      this.sort = {header: e.dataField, type: type ?? 'asc'};
      const directions = {
        asc: 1,
        desc: -1
      };
      const direction = directions[type] ?? 1;
      switch (typeCell) {
        case 'number':
          if (e.template)
            this.dataRows.sort((a, b) => direction * (e.template(a) - e.template(b)));
          else
            this.dataRows.sort((a, b) => direction * (a[e.dataField] - b[e.dataField]));
          break;
        case 'string': {
          if (e.template)
            this.dataRows.sort((a, b) => direction * e.template(a).localeCompare(e.template(b), ["ru", 'en'], {caseFirst: 'upper'}));
          else
            this.dataRows.sort((a, b) => direction * a[e.dataField].localeCompare(b[e.dataField], ["ru", 'en'], {caseFirst: 'upper'}));
          break;
        }
        default: {
          if (e.template)
            this.dataRows.sort((a, b) => direction * e.template(a).localeCompare(e.template(b), ["ru", 'en'], {caseFirst: 'upper'}));
          else
            this.dataRows.sort((a, b) => direction * a[e.dataField].localeCompare(b[e.dataField], ["ru", 'en'], {caseFirst: 'upper'}));
          break;
        }
      }
    },
    setTopPositions() {
      this.localHeaders = this.localHeaders.map(el => {
        const offsetHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetHeight : '';
        const offsetTop = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetTop : '';
        const clientHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientHeight : '';
        const clientWidth = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientWidth : '';
        return {
          ...el,
          offsetHeight: offsetHeight,
          offsetTop: offsetTop - 2,
          clientHeight: clientHeight,
          clientWidth: clientWidth
        }
      })
    },
    topFixedPositionForHeaderCell(cell) {
      return this.localHeaders.length > 0 ? this.localHeaders.find(el => el.dataField === cell.dataField) : ''
    },
    // eslint-disable-next-line no-unused-vars
    painCell(row, cell) {
      if (this.paintBackground(row))
        return this.paintBackground(row)();
    }
  },
}
</script>

<style lang="sass" scoped>
.table-grid
  &__container
    display: grid
    gap: 0
    width: 100%
    border-top: 2px solid #CFD8DC
    overflow-x: auto
    grid-auto-rows: minmax(30px, auto)
    position: relative
    z-index: 1
    overflow-y: auto
    max-height: 550px

  &-header-row
    gap: 0
    display: contents

  &-header__cell
    border-bottom: 2px solid #CFD8DC
    border-left: 1px solid #CFD8DC
    padding: 5px
    display: grid
    top: 0
    position: sticky
    position: -webkit-sticky
    justify-content: center
    align-items: center
    font-size: 14px
    font-weight: 500
    color: #89A0B0
    z-index: 2
    background-color: #FFFFFF

    &:first-child
      border-left: 0

  &-body-row
    border-bottom: 1px solid #CFD8DC
    gap: 0
    display: contents

    &__cell
      border-bottom: 1px solid #CFD8DC
      border-left: 1px solid #CFD8DC
      display: grid
      padding: 5px
      justify-content: center
      align-items: center
      color: #455A64
      background: white

      &:first-child
        border-left: 0
</style>