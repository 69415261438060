// eslint-disable-next-line no-unused-vars
const QuotaTableHeaders = (_this) => {
  return {
    countCells: 14,
    massive: [
      {
        dataField: 'sprOrgName',
        cols: 2,
        order: 0,
        rows: 1,
        text: 'Организация, осуществляющая образовательную деятельность',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        headerStyles: 'min-width: 200px'
      },
      {
        dataField: 'sprSpecName',
        cols: 1,
        order: 1,
        rows: 1,
        text: 'Направление подготовки, специальность, научная специальность',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'numPlaces',
        cols: 1,
        order: 2,
        rows: 1,
        text: 'Квота приёма на целевое обучение (всего по данному направлению)',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'actions',
        cols: 1,
        order: 3,
        rows: 1,
        text: '',
        headerClass: 'align-center justify-center',
        variable: true,
        addButton: true,
      },
      {
        dataField: 'requestAmount',
        cols: 1,
        order: 4,
        rows: 1,
        text: 'Объём заявки респондента',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'client',
        cols: 4,
        order: 5,
        rows: 1,
        text: 'Заказчик',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-left',
        headerStyles: 'min-width: 250px',
        headerClass: 'text-center',
        template: (row) => row.item ? row.item.zakazchik : ''
      },
      {
        dataField: 'subject',
        cols: 1,
        order: 6,
        rows: 1,
        text: 'Субъект трудоустройства',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'organization',
        cols: 2,
        order: 7,
        rows: 1,
        text: 'Организация трудоустройства',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerStyles: 'min-width: 100px',
        headerClass: 'text-center',
        template: (row) => row.item ? row.item.orgTrud : ''
      },
      {
        dataField: 'innerActions',
        cols: 1,
        order: 8,
        rows: 1,
        text: '',
        variable: true,
        addButton: false,
      },
    ].map((el) => ({
      ...el,
      class: el.class + ' pa-0',
      styles: (row) => {
        if (['sprOrgName', 'sprSpecName', 'numPlaces', 'actions'].includes(el.dataField))
          return !row.mainRow ? 'display: none!important;' : ''/*text-align: center; */
        else
          return ''
      },
      rowsDataCalc: (row) => {
        const len = row.array?.length
        if (['sprOrgName', 'sprSpecName', 'numPlaces', 'actions'].includes(el.dataField))
          return row.mainRow ? (len === 0 ? 1 : len) : 1;
        else
          return 1
      },
    })),
    organizationMassive: [
      {
        dataField: 'sprOrgName',
        cols: 1,
        rows: 1,
        text: 'Название',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // columnFilter: true,
      },
      {
        dataField: 'sprSpecName',
        cols: 1,
        rows: 1,
        text: 'Направление обучения',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // columnFilter: true,
      },
      {
        dataField: 'sprFoName',
        cols: 1,
        rows: 1,
        text: 'Форма обучения',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'numPlaces',
        cols: 1,
        rows: 1,
        text: 'Квота приёма на ЦО в выбранном вузе по данному направлению',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'totalRequestAmount',
        cols: 1,
        rows: 1,
        text: 'Объём суммарной заявки респондентов',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'val',
        cols: 1,
        rows: 1,
        text: 'Количество мест детализированных для целевого приема',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'client',
        cols: 4,
        rows: 1,
        text: 'Заказчик',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      // {
      //   dataField: 'organization',
      //   cols: 2,
      //   rows: 1,
      //   text: 'Организация трудоустройства (возможно не заполнено)',
      //   sortable: true,
      //   typeCell: 'string',
      //   variable: true,
      //   class: 'text-center',
      //   headerClass: 'text-center',
      // },
      // {
      //   dataField: 'subject',
      //   cols: 2,
      //   rows: 1,
      //   text: 'Субъект трудоустройства',
      //   sortable: true,
      //   typeCell: 'string',
      //   variable: true,
      //   class: 'text-center',
      //   headerClass: 'text-center',
      // },
      {
        dataField: 'sprOrgFounderName',
        cols: 2,
        rows: 1,
        text: 'Респондент',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
    ]
  };
}
export default QuotaTableHeaders