<!-- Превью секции с заголовком, футером, основным контентом.
Можно ставить фоновый цвет.	
Можно отправить контент через компонент в заголовок: <template v-slot:header>wwww</template> -->
<template>
  <section :class="['section-preview', background, customClass]">
    <v-container>
      <HeaderSection
        v-if="title || subtitle"
        :subtitle="subtitle"
        :title="title"
        class="section-preview__header"
      >
        <slot name="header"></slot>
      </HeaderSection>
      <slot name="default"></slot>
      <footer v-if="link || url" class="section-preview__footer">
        <router-link v-if="link" :to="url" class="section-preview__footer-link">{{ link }}</router-link>
      </footer>
    </v-container>
  </section>
</template>

<script>
import HeaderSection from '@/components/GridTable/dataFolder/HeaderSection';

export default {
  components: {
    HeaderSection
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    url: {
      type: String
    },
    link: {
      type: String
    },
    background: {
      type: String
    },
    customClass: {
      type: String,
    }
  }
}
</script>

<style lang="sass">
.section-preview_background_1
  background: #ffffff

.section-preview_background_2
  background: #fbf9f6

.section-preview_background_3
  background: #f5fafd

.section-preview_background_4
  background: #426075

.section-preview__footer
  display: flex
  justify-content: flex-start
  margin: 30px 0 20px 0

.section-preview__footer-link
  font-family: 'Roboto'
  font-weight: 400
  font-size: 18px
  line-height: 19.8px
  color: #00599B !important
  text-decoration: underline
.section-preview
  background: #fff
  border-radius: 6px
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
  margin-bottom: 20px
  width: 100%
  overflow-x: auto
</style>